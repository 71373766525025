import React from 'react'
import styled from 'styled-components'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'

import AuthBackgroundImage from '@assets/images/forgot-password.svg'
import AuthLayout from './AuthLayout'
import Title from './Title'
import Form from './Form'
import Input from './Input'
import SubmitButton from './SubmitButton'

const SubTitle = styled.div`
  margin: ${({ theme }) => theme.spacers.normal} 0;
`

function ForgotPassword({ onSubmit }) {
  const { register, handleSubmit, errors } = useForm({
    mode: 'onBlur',
    validationSchema: yup.object().shape({
      email: yup
        .string()
        .email('รูปแบบอีเมล์ไม่ถูกต้อง')
        .required('โปรดระบุอีเมล์'),
    }),
  })

  const submit = (auth) => onSubmit(auth)

  return (
    <AuthLayout Image={AuthBackgroundImage}>
      <Title>ลืมรหัสผ่านหรือ?</Title>
      <SubTitle>
        กรอกอีเมล์ของคุณ เพื่อเราจะได้ส่งขั้นตอนการตั้งค่ารหัสผ่านใหม่
      </SubTitle>
      <Form onSubmit={handleSubmit(submit)}>
        <Input
          type="email"
          name="email"
          ref={register}
          placeholder="Email Address"
          error={errors.email}
        />
        <SubmitButton>ตั้งค่ารหัสผ่าน</SubmitButton>
      </Form>
    </AuthLayout>
  )
}

export default ForgotPassword

import React, { useCallback, useContext } from 'react'
import { getFirebaseAuth } from '@babelcoder/gatsby-plugin-firebase'
import { navigate } from 'gatsby'

import { GlobalStateContext } from '@babelcoder/gatsby-theme-base/src/components/GlobalStateProvider'
import { actions } from '@babelcoder/gatsby-theme-base/src/store/reducer'
import AuthGuard, {
  TYPES,
} from '@babelcoder/gatsby-theme-base/src/components/AuthGuard'
import AuthForgotPassword from '@components/auth/ForgotPassword'
import authErrors from '@utils/auth-errors'

function AuthForgotPasswordPage() {
  const { dispatch } = useContext(GlobalStateContext)

  const onSubmit = useCallback(
    async ({ email }) => {
      try {
        const firebaseAuth = await getFirebaseAuth()
        await firebaseAuth.sendPasswordResetEmail(email)

        navigate('/auth/complete', {
          replace: true,
          state: { type: 'forgot-password' },
        })
      } catch (error) {
        let message =
          authErrors[error.code] ||
          'เกิดข้อผิดพลาดในการร้องขอการตั้งค่ารหัสผ่านใหม่ โปรดลองอีกครั้ง'

        dispatch({
          type: actions.showPopupMessage,
          popupMessage: {
            type: 'danger',
            title: 'เกิดข้อผิดพลาด',
            message,
          },
        })
      }
    },
    [dispatch]
  )

  return (
    <AuthGuard type={TYPES.AUTH_DENY}>
      <AuthForgotPassword onSubmit={onSubmit}></AuthForgotPassword>
    </AuthGuard>
  )
}

export default AuthForgotPasswordPage
